<template>
    <div class="community-page">
        <society-block></society-block>
        <news-block :news-count="newsCount" :use-small-items="false">
            <template v-slot:header>
                <h2>{{$i18n.get(`Latest news`)}}</h2>
            </template>
            <template v-slot:see-all>{{$i18n.get(`See all news`)}}</template>
        </news-block>
        <values-block></values-block>
        <light-block></light-block>
        <bottom-society-block></bottom-society-block>
    </div>
</template>

<script>
import SocietyBlock from './snippets/SocietyBlock.vue'
import NewsBlock from "../home/snippets/NewsBlock.vue";
import ValuesBlock from "./snippets/ValuesBlock.vue";
import LightBlock from "./snippets/LightBlock";
import BottomSocietyBlock from "./snippets/BottomSocietyBlock";

export default {
    name: 'community',
    components: {
        'society-block': SocietyBlock,
        'news-block': NewsBlock,
        'values-block': ValuesBlock,
        'light-block': LightBlock,
        'bottom-society-block': BottomSocietyBlock,
    },
    computed: {
        newsCount() {
            return this.$store.state.isTablet ? 3 : 3
        }
    }
}
</script>
<style lang="less">
@media (min-width: 768px) and (max-width: 1023px) {
    .community-page {
        .news-item {
            width: calc((100% - 36px) / 2);
            max-width: calc((100% - 36px) / 2);
        }
    }
}
</style>