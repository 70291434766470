<template>
    <w-modal class="-show-full-description" :style="modalStyle">
        <template #title>
            <div v-if="icon" class="icon" v-html="icon"></div>
            {{title}}
        </template>

        <div class="description-wrapper" v-html="description"></div>
    </w-modal>
</template>

<script>
export default {
    name: 'full-description',
    props: {
        icon: String,
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        }
    },
    computed: {
        modalStyle() {
            if (this.$store.state.isMobile || this.$store.state.isTablet) {
                return {
                    'max-height': window.innerHeight + 'px',
                    overflowY: 'scroll'
                }
            }

            return '';
        }
    }
}
</script>

<style lang="less">
.-show-full-description {
    padding-bottom: 50px !important;

    .icon {
        width: 80px;
        height: 80px;
        background: var(--promo-gradient);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 14px;
        margin-top: -63px;
    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        background: linear-gradient(82.63deg, #FCB42F -51.45%, #CB34E3 149.34%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        display: inline-block;
    }

    .description-wrapper {
        margin-top: 10px;

        > p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: var(--dark-blue);

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width: 400px) {
    .-show-full-description {
        padding-bottom: 20px;
    }
}
</style>