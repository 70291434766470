<template>
    <a class="news-item">
        <div class="poster"></div>

        <div class="tag">
        </div>

        <div class="description">

            <div class="date">
            </div>

            <div class="title">
            </div>

        </div>
    </a>
</template>

<script>

export default {
    name: 'news-item-preloader'
}
</script>

<style lang="less" scoped>
.news-item {
    width: 30%;
    position: relative;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    flex-grow: 1;

    > .poster {
        height: 180px;
        background: var(--dark-blue-10);
        border-radius: 20px 20px 0px 0px;
        background-position: left;
        background-size: cover;
    }

    > .tag {
        width: 45px;
        height: 24px;
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 4px 9px;
        background: var(--dark-blue-10);
        border-radius: 100px;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        backdrop-filter: blur(20px);
        color: var(--white);
    }

    > .description {
        min-height: 108px;
        background-color: var(--white);
        border-radius: 0px 0px 20px 20px;
        padding: 20px;
        flex-grow: 1;
        position: relative;

        > .tag {
            display: none;
        }

        > .date {
            height: 12px;
            width: 60%;
            border-radius: 100px;
            background-color: var(--dark-blue-10);
            margin-bottom: 5px;
        }

        > .title {
            max-width: 100%;
            height: 25px;
            border-radius: 100px;
            background-color: var(--dark-blue-10);
        }

    }
}

@media (max-width: 1023px) {
    .news-item {
        width: 326px;
    }
}

@media (max-width: 767px) {
    .news-item {
        width: 290px;
        
        .poster {
            height: 160px;
        }
    }
}
</style>