<template>
    <div class="media-buttons">
        <w-button v-for="(btn, i) in svgMediaLinks" size="xs" :class="btn.btnAdditionalClass" @click="btn.action" :key="i">
            <span class="button-svg-container" v-html="btn.svg"></span>
            {{ btn.title }}
        </w-button>
    </div>
</template>

<script>
export default {
    name: 'media-buttons',
    data() {
        return {
            svgMediaLinks: [
                {
                    svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.353259 9.6426L4.96166 11.3626L6.74539 17.0991C6.85953 17.4665 7.30879 17.6023 7.60699 17.3585L10.1758 15.2643C10.4451 15.0449 10.8286 15.034 11.11 15.2383L15.7432 18.6021C16.0622 18.8339 16.5141 18.6591 16.5941 18.2737L19.9882 1.9476C20.0755 1.52653 19.6618 1.17527 19.2609 1.33033L0.347859 8.6264C-0.118874 8.8064 -0.114808 9.4672 0.353259 9.6426ZM6.45793 10.447L15.4645 4.89987C15.6263 4.80047 15.7929 5.01933 15.6539 5.14827L8.22086 12.0576C7.95959 12.3008 7.79106 12.6263 7.74333 12.9795L7.49013 14.8559C7.45659 15.1065 7.10466 15.1313 7.03553 14.8889L6.06173 11.4672C5.95019 11.0769 6.11273 10.6601 6.45793 10.447Z" fill="white"/></svg>',
                    title: this.$i18n.get(`TG Channel`),
                    btnAdditionalClass: '-blue-gradient',
                    action: this.openTelegramChannel
                },
                {
                    svg: '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.2477 3.17085C14.0825 2.6257 12.8367 2.2295 11.5342 2.00391C11.3742 2.29311 11.1873 2.68211 11.0585 2.99155C9.67387 2.78332 8.30201 2.78332 6.94287 2.99155C6.81404 2.68211 6.62292 2.29311 6.46152 2.00391C5.15761 2.2295 3.91032 2.62716 2.74514 3.17374C0.394982 6.72514 -0.242108 10.1883 0.0764375 13.6024C1.63519 14.7664 3.14581 15.4735 4.63093 15.9362C4.99762 15.4316 5.32465 14.8951 5.60638 14.3297C5.06981 14.1258 4.5559 13.8742 4.07031 13.5821C4.19913 13.4867 4.32514 13.3869 4.44689 13.2842C7.40865 14.6695 10.6267 14.6695 13.5531 13.2842C13.6762 13.3869 13.8022 13.4867 13.9296 13.5821C13.4426 13.8756 12.9273 14.1273 12.3907 14.3312C12.6724 14.8951 12.9981 15.433 13.3662 15.9377C14.8527 15.4749 16.3647 14.7678 17.9235 13.6024C18.2973 9.64464 17.285 6.21325 15.2477 3.17085ZM6.00988 11.5028C5.12079 11.5028 4.39166 10.6727 4.39166 9.66198C4.39166 8.65122 5.10522 7.81977 6.00988 7.81977C6.91457 7.81977 7.64367 8.64977 7.6281 9.66198C7.62951 10.6727 6.91457 11.5028 6.00988 11.5028ZM11.9901 11.5028C11.101 11.5028 10.3718 10.6727 10.3718 9.66198C10.3718 8.65122 11.0854 7.81977 11.9901 7.81977C12.8947 7.81977 13.6238 8.64977 13.6083 9.66198C13.6083 10.6727 12.8947 11.5028 11.9901 11.5028Z" fill="white"/></svg>',
                    title: this.$i18n.get(`Discord`),
                    btnAdditionalClass: '-purple-gradient',
                    action: this.openDiscord,
                },
                {
                    svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_624)"><path d="M20 3.79875C19.2563 4.125 18.4637 4.34125 17.6375 4.44625C18.4875 3.93875 19.1363 3.14125 19.4412 2.18C18.6488 2.6525 17.7738 2.98625 16.8412 3.1725C16.0887 2.37125 15.0162 1.875 13.8462 1.875C11.5763 1.875 9.74875 3.7175 9.74875 5.97625C9.74875 6.30125 9.77625 6.61375 9.84375 6.91125C6.435 6.745 3.41875 5.11125 1.3925 2.6225C1.03875 3.23625 0.83125 3.93875 0.83125 4.695C0.83125 6.115 1.5625 7.37375 2.6525 8.1025C1.99375 8.09 1.3475 7.89875 0.8 7.5975C0.8 7.61 0.8 7.62625 0.8 7.6425C0.8 9.635 2.22125 11.29 4.085 11.6712C3.75125 11.7625 3.3875 11.8062 3.01 11.8062C2.7475 11.8062 2.4825 11.7913 2.23375 11.7362C2.765 13.36 4.2725 14.5538 6.065 14.5925C4.67 15.6838 2.89875 16.3412 0.98125 16.3412C0.645 16.3412 0.3225 16.3263 0 16.285C1.81625 17.4563 3.96875 18.125 6.29 18.125C13.835 18.125 17.96 11.875 17.96 6.4575C17.96 6.27625 17.9538 6.10125 17.945 5.9275C18.7588 5.35 19.4425 4.62875 20 3.79875Z" fill="white"/></g><defs><clipPath id="clip0_1_624"><rect width="20" height="20" fill="white"/></clipPath></defs></svg>',
                    title: this.$i18n.get(`Twitter`),
                    btnAdditionalClass: ' btn -light-blue-gradient',
                    action: this.openTwitter
                },
                // {
                //     svg: '<svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.94101 10.0485L9.43689 6.81554H6.33494V4.7175C6.33494 3.83302 6.76823 2.97087 8.15762 2.97087H9.56796V0.218441C9.56796 0.218441 8.28802 0 7.06433 0C4.50956 0 2.8398 1.54834 2.8398 4.35146V6.81554H0V10.0485H2.8398V17.8641C3.41791 17.9547 4.00221 18.0002 4.58738 18C5.1819 18 5.76552 17.9535 6.33494 17.8641V10.0485H8.94101Z" fill="white"/></svg>',
                //     title: this.$i18n.get(`Facebook`),
                //     btnAdditionalClass: ' btn -fb-gradient',
                //     action: this.openFacebook,
                // },
                {
                    svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.353259 9.6426L4.96166 11.3626L6.74539 17.0991C6.85953 17.4665 7.30879 17.6023 7.60699 17.3585L10.1758 15.2643C10.4451 15.0449 10.8286 15.034 11.11 15.2383L15.7432 18.6021C16.0622 18.8339 16.5141 18.6591 16.5941 18.2737L19.9882 1.9476C20.0755 1.52653 19.6618 1.17527 19.2609 1.33033L0.347859 8.6264C-0.118874 8.8064 -0.114808 9.4672 0.353259 9.6426ZM6.45793 10.447L15.4645 4.89987C15.6263 4.80047 15.7929 5.01933 15.6539 5.14827L8.22086 12.0576C7.95959 12.3008 7.79106 12.6263 7.74333 12.9795L7.49013 14.8559C7.45659 15.1065 7.10466 15.1313 7.03553 14.8889L6.06173 11.4672C5.95019 11.0769 6.11273 10.6601 6.45793 10.447Z" fill="white"/></svg>',
                    title: this.isMobile ? this.$i18n.get(`Chat`) : this.$i18n.get(`TG Chat`),
                    btnAdditionalClass: ' btn -blue-gradient',
                    action: this.openTelegramChat
                },
                // {
                //     svg: '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5655 6.66525C8.72925 6.66525 7.23074 8.16376 7.23074 10C7.23074 11.8362 8.72925 13.3348 10.5655 13.3348C12.4017 13.3348 13.9002 11.8362 13.9002 10C13.9002 8.16376 12.4017 6.66525 10.5655 6.66525ZM20.5672 10C20.5672 8.61907 20.5798 7.25064 20.5022 5.87221C20.4247 4.27113 20.0594 2.85017 18.8886 1.67938C17.7153 0.506085 16.2969 0.14334 14.6958 0.065788C13.3148 -0.0117644 11.9464 0.000744113 10.568 0.000744113C9.18706 0.000744113 7.81864 -0.0117644 6.4402 0.065788C4.83912 0.14334 3.41816 0.508587 2.24737 1.67938C1.07408 2.85267 0.711334 4.27113 0.633781 5.87221C0.556229 7.25314 0.568737 8.62157 0.568737 10C0.568737 11.3784 0.556229 12.7494 0.633781 14.1278C0.711334 15.7289 1.07658 17.1498 2.24737 18.3206C3.42066 19.4939 4.83912 19.8567 6.4402 19.9342C7.82114 20.0118 9.18956 19.9993 10.568 19.9993C11.9489 19.9993 13.3174 20.0118 14.6958 19.9342C16.2969 19.8567 17.7178 19.4914 18.8886 18.3206C20.0619 17.1473 20.4247 15.7289 20.5022 14.1278C20.5823 12.7494 20.5672 11.3809 20.5672 10ZM10.5655 15.131C7.72607 15.131 5.43452 12.8394 5.43452 10C5.43452 7.16058 7.72607 4.86903 10.5655 4.86903C13.4049 4.86903 15.6965 7.16058 15.6965 10C15.6965 12.8394 13.4049 15.131 10.5655 15.131ZM15.9066 5.8572C15.2437 5.8572 14.7083 5.32184 14.7083 4.65889C14.7083 3.99594 15.2437 3.46058 15.9066 3.46058C16.5695 3.46058 17.1049 3.99594 17.1049 4.65889C17.1051 4.81631 17.0742 4.97222 17.0141 5.1177C16.9539 5.26317 16.8657 5.39535 16.7544 5.50666C16.6431 5.61798 16.5109 5.70624 16.3654 5.76639C16.2199 5.82654 16.064 5.8574 15.9066 5.8572Z" fill="white"/></svg>',
                //     title: this.$i18n.get(`Instagram`),
                //     btnAdditionalClass: 'btn -inst-gradient',
                //     action: this.openInstagram,
                // }
            ]
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    methods: {
        openTelegramChannel() {
            window.open(this.$store.state.links.telegramNews)
        },
        openTelegramChat() {
            window.open(this.$store.state.links.telegramCommunity)
        },
        openTwitter() {
            window.open(this.$store.state.links.twitter)
        },
        openBitcointalk() {
            window.open('')
        },
        // openInstagram() {
        //     //TODO переход в instagram
        // },
        openDiscord() {
            window.open(this.$store.state.links.discord)
        },
        // openFacebook() {
        //     //TODO переход в facebook
        // }
    }
}
</script>

<style lang="less">
.media-buttons {
    .btn {
        padding: 6px 14px 6px 10px !important;
        font-weight: normal !important;
        font-size: 12px !important;
        line-height: 180% !important;
        color: var(--white);
        column-gap: 6px;

        &.-blue-gradient {
            background: linear-gradient(102.48deg, #1079D9 8.34%, #3B89FE 111.45%);
            &:hover {
                background: linear-gradient(102.48deg, #3191E9 8.34%, #70A9FF 111.45%);
            }
        }

        &.-light-blue-gradient {
            background: linear-gradient(102.48deg, #108ED9 8.34%, #55BDFF 111.45%);

            &:hover {
                background: linear-gradient(102.48deg, #39ADF1 8.34%, #8FD4FF 111.45%);
            }
        }

        &.-inst-gradient {
            background: linear-gradient(102.48deg, #7728C5 8.34%, #D72A7B 111.45%);

            &:hover {
                background: linear-gradient(102.48deg, #8645c6 8.34%, #d74589 111.45%);
            }
        }

        &.-purple-gradient {
            background: linear-gradient(102.48deg, #4450E4 8.34%, #313FEC 111.45%);

            &:hover {
                background: linear-gradient(102.48deg, #626DEAFF 8.34%, #4550e0 111.45%);
            }
        }

        &.-fb-gradient {
            background: linear-gradient(102.48deg, #3066BC 8.34%, #568FEA 111.45%);

            &:hover {
                background: linear-gradient(102.48deg, #3e73c2 8.34%, #5f94ea 111.45%);
            }
        }
    }

    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 10px;

    .button-svg-container {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 767px) {
    .media-buttons {
        margin-top: 15px;

        > button {
            width: 49%;
        }
    }
}
</style>