<template>
    <div class="news-block">
        <div class="container">
            <div class="head">
                <div class="mobile-header">
                    <h2 class="title">
                        <slot name="header">
                            {{ $i18n.get(`News`) }}
                        </slot>
                    </h2>

                </div>

                <div class="right-button">
                    <w-button type="border" size="small" class="link" :to="{name: 'news'}">
                        <slot name="see-all">
                            {{ $i18n.get(`See all`) }}
                        </slot>
                    </w-button>
                </div>
            </div>

            <div class="content">
                <news-item v-for="(item, index) of news"
                    :news="item"
                    :is-small="isSmall()"
                    :key="index">
                </news-item>

                <template v-if="homeNews.length < 1">
                    <news-item-preloader v-for="item of 6" :key="item"></news-item-preloader>
                </template>
            </div>

            <w-button class="link" type="border" size="xs" :to="{name: 'news'}">
                {{ $i18n.get(`See all`) }}
            </w-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import NewsItem from '../../community/snippets/NewsItem.vue'
import NewsItemPreloader from '../../community/snippets/NewsItemPreloader.vue'

export default {
    name: 'news-block',
    props: {
        newsCount: {
            type: Number,
            default: 6
        },
        useSmallItems: {
            type: Boolean,
            default: true
        }
    },
    components: {
        'news-item': NewsItem,
        'news-item-preloader': NewsItemPreloader

    },
    computed: {
         ...mapGetters('news', {
            hasHomeNews: 'hasHomeNews',
            isLoading: 'isLoading',
            homeNews: 'getHomeNews'
        }),
        news() {
            return this.homeNews.slice(0, this.newsCount)
        }
    },
    methods: {
        ...mapActions('news', {
            loadNews: 'loadNews',
        }),
        isSmall() {
            return this.$store.state.isMobile && this.useSmallItems
        }
    },
    created() {
        if (!this.hasHomeNews) {
            this.loadNews()
        }
    }
}
</script>

<style lang="less">
.news-block {
    background-color: var(--main-grey);
    color: var(--font-dark-blue);
    padding: 15px 15px 30px 15px;

    > .container {
        max-width: var(--main-width-full);
        margin: 0 auto;

        > .head {
            display: block;

            > .mobile-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                > .title {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 144%;
                }

                > .bell-button {
                    width: 46px;
                    height: 35px;
                    padding: 8px 14px 3px 14px;
                    border: 1px solid rgba(0, 26, 84, 0.15);
                    border-radius: 100px;
                    transition: all 0.2s;
                    > .bell {
                        fill: var(--font-dark-blue);
                        fill-opacity: 1;
                    }
                }

                > .bell-button:hover {
                    cursor: pointer;
                    border: 1px solid var(--dark-blue-40);
                }
            }

            > .right-button {
                display: none;
            }
        }

        > .content {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            margin-top: 16px;
            align-items: stretch;
            column-gap: 16px;
            row-gap: 16px;

        }

        > .link {
            margin: 16px auto 0px auto;
        }

        > .link:hover {
            background: none;
            box-shadow: 0px 0px 0px 1px var(--light-blue-hover);
        }
    }
}

@media (min-width: 768px) {
    .news-block {
        padding: 50px 15px 24px 15px;

        > .container {
            margin: 0px auto 24px auto;

            > .head {
                display: flex;
                justify-content: space-between;
                align-items: center;

                > .mobile-header {
                    justify-content: start;
                    column-gap: 15px;

                    > .title {
                        font-size: 36px;
                        line-height: 144%;
                    }
                }

                > .right-button {
                    display: block;
                }
            }

            > .content {
                row-gap: 16px;
                > :nth-child(3) {
                    display: none;
                }
            }

            > .link {
                display: none;
                margin-top: 16px;
            }
        }
    }
}

@media (min-width: 1024px) {
    .news-block {
        padding-top: 100px;

        > .container {
            margin: 0px auto 86px auto;

            > .head {
                > .mobile-header {
                    > .title {
                        font-size: 46px;
                        line-height: 144%;
                    }
                }
            }

            > .content {
                column-gap: 18px;
                row-gap: 26px;

                > :nth-child(3) {
                    display: inherit;
                }
            }
        }
    }
}

@media (min-width: 1279px) {
    .news-block {
        padding-top: 100px;

        > .container {
            margin: 0px auto 86px auto;

            > .head {
                > .mobile-header {
                    > .title {
                        font-size: 46px;
                        line-height: 144%;
                    }
                }
            }

            > .content {
                column-gap: 18px;
                row-gap: 26px;
            }
        }
    }
}
</style>
