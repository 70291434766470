<template>
    <div class="society-block">
        <div class="container">
            <h1 class="title">
                {{ $i18n.get(`TRUE Community`) }}
            </h1>

            <div class="description" v-html="$i18n.get(`A community of like-minded people and token holders who share our values and enjoy interacting with the game world the way they want and anytime they want. In just a click.`)">            </div>

            <media-buttons></media-buttons>
        </div>
    </div>
</template>

<script>
import MediaButtons from "./MediaButtons";

export default {
    name: 'society-block',
    components: {
        'media-buttons': MediaButtons
    }
}
</script>

<style lang="less" scoped>
.society-block {
    background-image: url(../images/new-society-block-bg.webp);
    background-size: 2560px 100%;
    background-position: center;
    color: var(--white);
    background-repeat: no-repeat;
    padding: 83px 15px 105px 15px;
    min-height: 440px;

    > .container {
        max-width: var(--main-width-full);
        margin: 0 auto;

        > .title {
            font-weight: bold;
            font-size: 46px;
            line-height: 60px;
        }

        > .description {
            margin-top: 10px;
            font-weight: 500;
            font-size: 18px;
            line-height: 32px;
            max-width: 600px;
        }
    }
}

@media (max-width: 1279px) {
    .society-block {
        > .container {
            > .description {
                font-size: 16px;
                line-height: 28px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .society-block {
        padding: 53px 15px 80px 15px;
        background-image: url(../images/new-society-block-bg.webp);
        background-position: center;
        background-size: auto 100%;
        min-height: 390px;

        > .container {
            > .title {
                font-size: 36px;
                line-height: 54px;
            }

            > .description {
                max-width: 502px;
                margin-top: 10px;
            }
        }
    }
}

@media (max-width: 767px) {
    .society-block {
        text-align: center;
        padding: 0;
        background-image: url(../images/new-society-block-bg.webp);
        background-position: top 0 right 37%;
        background-size: 1800px 310px;
        background-color: var(--dark-blue);
        height: 360px;

        > .container {
            background-color: var(--dark-blue-80);
            padding: 19px 15px 36px 15px;
            height: 100%;
            
            > .title {
                font-weight: bold;
                font-size: 24px;
                line-height: 36px;
            }

            > .description {
                max-width: 502px;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                margin: 10px auto 0;
            }
        }
    }
}
</style>
