<template>
    <div class="values-block-item">
        <template v-if="$store.state.isMobile && useExpansionPanel">
            <w-expansion-panel>
                <template v-slot:title>{{item.title}}</template>
                <template v-slot:description>
                    <div v-html="expansionPanelDescription"></div>
                </template>
            </w-expansion-panel>
        </template>

        <template v-else>
            <div class="head">
                <slot name="icon"></slot>

                <div class="titles">
                    <div class="title">
                        {{item.title}}
                    </div>

                    <div class="subtitle" v-if="item.subtitle">
                        {{item.subtitle}}
                    </div>
                </div>
            </div>

            <div class="description" v-html="itemDescription"></div>

            <w-button @click="showFullDescription(item)" :type="btnType" size="xs" v-if="showMoreBtn(item)">
                {{$i18n.get(`More`)}}
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10.9998" cy="11.9985" r="11" :fill="iconColor" fill-opacity="0.1"/>
                    <path
                        d="M14.9998 12.7515H11.8311V15.9985H10.1684V12.7515H6.99976V11.2456H10.1684V7.99854H11.8311V11.2456H14.9998V12.7515Z"
                        :fill="iconColor"/>
                </svg>
            </w-button>

            <slot name="default"></slot>
        </template>
    </div>
</template>

<script>
export default {
    name: 'values-block-item',
    props: {
        item: Object,
        dark: {
            type: Boolean,
            default: true
        },
        useExpansionPanel: {
            type: Boolean,
            default: true
        },
        useIcon: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        showFullDescription(item) {
            this.$modal.show(() => import(/* webpackChunkName: 'community' */ '../modals/FullDescription.vue'), {
                icon: item.icon,
                title: item.title,
                description: item.description + ' ' + item.additionalDescription
            }, {
                name: 'show-full-description',
                width: 550
            })
        },
        showMoreBtn(item) {
            return item.additionalDescription && !this.$store.state.isMobile
        }
    },
    computed: {
        iconColor() {
            return this.dark ? 'white' : '#001A54'
        },
        btnType() {
            return this.dark ? 'white-border' : 'border'
        },
        itemDescription() {
            return !this.useExpansionPanel && this.$store.state.isMobile && this.item.additionalDescription
                ? this.item.description + this.item.additionalDescription
                : this.item.description
        },
        expansionPanelDescription() {
            return this.item.description + (this.item.additionalDescription ? this.item.additionalDescription : '')
        }
    }
}
</script>

<style lang="less">
.values-block-item {
    padding: 35px;
    background-color: var(--white-10);
    backdrop-filter: blur(80px);
    border-radius: 30px;
    flex-grow: 1;
    z-index: 0;

    > .head {
        display: flex;
        flex-direction: column;

        > .titles {
            > .title {
                display: inline-block;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                background: linear-gradient(82.63deg, #FCB42F -51.45%, #CB34E3 149.34%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }

            .subtitle {
                font-weight: 700;
                font-size: 22px;
                line-height: 33px;
                color: var(--dark-blue);
            }
        }
    }

    > .description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-top: 8px;

        > b {
            font-weight: 600;
        }
    }

    > button {
        padding-left: 15px;
        padding-right: 10px;
        margin-top: 20px;
    }
}

@media (max-width: 1023px) {
    .values-block-item {
        padding: 35px;
        border-radius: 30px;
        flex-grow: 1;

        .head > .titles > .title {
            font-size: 20px;
            line-height: 26px;
        }
    }
}

@media (max-width: 767px) {
    .values-block-item {
        padding: 0;

        > .head {
            flex-direction: row;
            align-items: center;
        }

        > .description {
            font-size: 12px;
            line-height: 20px;

            > p {
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
</style>
