<template>
    <router-link class="news-item" :class="classes" :to="{name: 'single-news', params: {slug: this.news.slug}}">
        <div class="poster" :style="posterBackground"></div>

        <div class="tag" :style="tagBackground">
            #{{ news.tag.title }}
        </div>

        <div v-if="!isActive" class="status">
            Inactive
        </div>

        <div class="description">
            <div v-if="isSmall" class="tag" :style="tagColor">
                #{{ news.tag.title }}
            </div>

            <div class="date">
                {{ date }}
            </div>

            <div class="title">
                {{ news.shortTitle }}
            </div>

            <div v-if="news.pinned" class="pin" :style="pinBackground">
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.8381 3.06255L11.1864 0.410756C10.9215 0.145877 10.5683 0 10.192 0C9.81562 0 9.46246 0.145877 9.19758 0.410756L8.22213 1.38621C8.13419 1.47412 8.08481 1.59334 8.08481 1.71765C8.08481 1.84196 8.13419 1.96122 8.22213 2.04912L8.88517 2.71217L7.0503 4.547L5.38705 4.88379C4.94586 4.44257 4.35729 4.19959 3.72978 4.19959C3.10227 4.19959 2.5137 4.4426 2.07247 4.88382L1.05917 5.89715C0.876109 6.08021 0.876109 6.377 1.05917 6.56007L7.68877 13.1897C7.77668 13.2776 7.8959 13.327 8.02022 13.327C8.14456 13.327 8.26378 13.2776 8.35169 13.1897L9.36502 12.1764C9.80624 11.7351 10.0492 11.1466 10.0492 10.5191C10.0492 9.89155 9.80627 9.30295 9.36502 8.86176L9.70184 7.19854L11.5367 5.3637L12.1998 6.02678C12.2877 6.11468 12.4069 6.16409 12.5313 6.16409C12.6556 6.16409 12.7748 6.11468 12.8627 6.02678L13.8381 5.05132C14.103 4.78645 14.2489 4.43332 14.2489 4.05697C14.2489 3.68055 14.103 3.32742 13.8381 3.06255Z" fill="white"/>
                    <path d="M0.387299 13.2001C0.204234 13.3832 0.204234 13.68 0.387299 13.863C0.478832 13.9546 0.598802 14.0003 0.718772 14.0003C0.838743 14.0003 0.958714 13.9546 1.05022 13.863L4.04379 10.8694L3.38087 10.2065L0.387299 13.2001Z" fill="white"/>
                </svg>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'news-item',
    props: {
        news: {
            type: Object,
            default: {}
        },
        isSmall: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        posterBackground() {
            return {
                backgroundImage: 'url(' + this.news.posterSmall + ')'
            }
        },
        tagBackground() {
            return {
                backgroundColor: this.$functions.hexToRGB(this.news.tag.color, 0.6)
            }
        },
        pinBackground() {
            return {
                backgroundColor: this.news.tag.color
            }
        },
        tagColor() {
            return {
                color: this.news.tag.color
            }
        },
        classes() {
            return { 
                '-small': this.isSmall,
                '-pinned' : this.news.pinned
            }
        },
        date() {
            return this.$moment(this.news.date).format('MMMM D, YYYY')
        },
        isActive() {
          return this.news.status === 1;
        }
    }
}
</script>

<style lang="less">
.news-item {
    width: calc((100% - 36px) / 3);
    position: relative;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    flex-grow: 1;
    max-width: calc((100% - 36px) / 3);
    background-color: var(--white);
    border-radius: 20px;
    box-shadow: 0px 1px 2px rgba(0, 26, 84, 0.15);

    > .poster {
        height: 180px;
        background: #ccc;
        border-radius: 20px;
        background-position: left;
        background-size: cover;
    }

    > .tag, .status {
        position: absolute;
        top: 10px;
        padding: 4px 9px;
        background: rgba(238, 238, 238, 0.377);
        border-radius: 100px;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        backdrop-filter: blur(20px);
        color: var(--white);
    }

    > .tag {
      left: 10px;
    }

    > .status {
      right: 10px;
    }

    > .description {
        min-height: 90px;
        background-color: var(--white);
        border-radius: 0px 0px 20px 20px;
        padding: 20px;
        flex-grow: 1;
        position: relative;

        > .tag {
            display: none;
        }

        > .date {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: var(--dark-blue-40);
        }

        > .title {
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: var(--dark-blue);
            margin: 2px 0px;
        }

        > .pin {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            position: absolute;
            right: 20px;
            top: -16px;
            border-radius: 50%;
        }
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 15px 40px rgba(0, 26, 84, 0.15);
        cursor: pointer;
    }

    &.-pinned {
        .description {
            background-color: var(--white);

            > .date {
                color: var(--dark-blue-40)
            }

            > .title {
                color: var(--dark-blue)
            }
        }
    }
}

@media (max-width: 1023px) {
    .news-item {
        width: 326px;
        max-width: calc((100% - 36px) / 3);
    }
}

@media (max-width: 767px) {
    .news-item {
        width: 290px;
        max-width: 100%;
        border-radius: 10px;

        .poster {
            height: 160px;
            border-radius: 10px;
        }

        &.-small {
            flex-direction: row;
            column-gap: 15px;

            > .poster {
                width: 90px;
                height: 90px;
                flex-basis: 90px;
                border-radius: 10px;
                flex-shrink: 0;
            }

            > .tag {
                display: none;
            }

            > .description {

                min-height: 0;
                flex-grow: 0;
                width: 155px;
                background: none;
                padding: 0;
                display: flex;
                flex-direction: column;

                > .tag {
                    display: block;
                    order: 1;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                }

                > .title {
                    order: 2;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 22px;
                    color: rgba(0, 26, 84, 1);
                }

                > .date {
                    order: 3;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: var(--dark-blue-40);
                }

                > .pin {
                    display: none;
                }
            }
        }
    }
}
</style>
