<template>
    <div class="values-block">
        <div class="container">
            <div class="title">
                <h2>{{ $i18n.get(`Values`) }} </h2> <span class="subtitle">{{ $i18n.get(`share our vision`) }}</span>
            </div>

            <div class="blocks">
                <div class="column" v-for="(column, index) of filteredItems" :key="index">
                    <values-block-item v-for="(item, index) of column" :key="index + 0.1" :item="item"></values-block-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ValuesBlockItem from "./ValuesBlockItem";

export default {
    name: 'values-block',
    components: {
        'values-block-item': ValuesBlockItem
    },
    data() {
        return {
            items: {
                reality: {
                    title: this.$i18n.get(`Reality`),
                    description: this.$i18n.get(`<p>When creating a crypto product, it is important not to lose touch with the real world — the <b>TRUE</b> world. To look beyond that online side. To understand that even though blockchain penetrates our day-to-day routine and finances more and more every year, it can only exist in synergy. And what we want to do is to find a fine line between the virtual/intangible and the familiar physical world. To build <b>something real</b> by taking advantage of the blockchain. In other words, <b>to stay in touch with reality</b> whatever it takes.</p>`),
                    additionalDescription: this.$i18n.get(`<p>That is why we do not take our community as an «army» or «squad» or something like that, which implies a strict obedience to a leader and a must to blindly follow the project. Our community is our partners. These are people who realize the importance of having a tangible result of work, and not a hype. People who share our approach, our vision and our values, and who are willing to develop them together.</p><p>We are partners because we have a common goal — to follow the line between physical and virtual, using the benefits of blockchain. And, of course, to make TFT an influential token that steadily goes up. A strong token is a solid foundation for the future TRUE ecosystem.</p>`)
                },
                partnership: {
                    title: this.$i18n.get(`Partnership`),
                    description: this.$i18n.get(`<p></p><b>TRUE</b> and a holder are two equivalent parties interested in one thing — the development of the project. On the way to the goal, we hear each other and make co-decisions that seem optimal here and now.</p>`)
                },
                honesty: {
                    title: this.$i18n.get(`Honesty`),
                    description: this.$i18n.get(`<p>No crypto project can successfully develop without the participation of holders. And honesty is the foundation of any strong relationship. <b>TRUE</b> is our common brainchild, so it is important for us to talk about it with maximum transparency. Enjoy the ups and share the bitterness of failure. <b>Together</b>.</p>`)
                },
                mindfulness: {
                    title: this.$i18n.get(`Mindfulness`),
                    description: this.$i18n.get(`<p>We do not have a ready-made recipe for wealth for those who want to skyrocket overnight. As well as we don't have (and neither does anyone) a clear idea of what blockchain or GameFi industry will be like in 5 years. We gladly share the information we have and work hard to fulfil the given promises thoroughly and on time. And you, in your turn, need to analyze it and make decisions on your own. Responsibility for the result lies with us together.</p>`)
                },
                professionalism: {
                    title: this.$i18n.get(`Professionalism`),
                    description: this.$i18n.get(`<p>We have assembled a team of True professionals to launch cutting edge crypto products. Yes, we are not the pioneers of many features. But we have a valuable iGaming experience and an expert approach to creating a real business to allow us to make the best version of the project for our holders.</p>`)
                },
            }
        }
    },
    computed: {
        filteredItems() {
            if (!this.$store.state.isDesktop) {
                return [ this.items ]
            } else {
                return [
                    [
                        this.items.reality
                    ],
                    [
                        this.items.partnership, this.items.mindfulness
                    ],
                    [
                        this.items.honesty, this.items.professionalism
                    ],
                ]
            }
        }
    },
}
</script>

<style lang="less">
.values-block {
    background-color: var(--dark-blue);
    background-image: url(../images/values-background.webp);
    background-size: 2560px 100%;
    background-position: center;
    min-height: 870px;
    color: var(--white);
    padding: 90px 15px;

    > .container {
        width: var(--main-width-full);
        margin: 0 auto;

        > .title {

            > h2 {
                font-weight: 800;
                font-size: 36px;
                line-height: 144%;
                display: inline-block;
            }

            > .subtitle {
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 33px;
                color: var(--white);
                opacity: 0.6;
            }
        }

        > .blocks {
            margin-top: 30px;
            display: flex;
            column-gap: 20px;
            align-items: flex-start;

            > .column {
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                // width: calc(100% / 3);
            }
        }
    }
}

@media (max-width: 1023px) {
    .values-block {
        padding: 45px 15px 65px;
        min-height: unset;

        > .container {

            > .title {

                > h2 {
                    font-size: 36px;
                    line-height: 144%;
                }
            }

            > .blocks {
                margin-top: 30px;
                display: flex;
                column-gap: 20px;

                > .column {
                    display: flex;
                    flex-direction: column;
                    row-gap: 20px;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .values-block {
        padding: 20px 15px 35px;
        background-size: 1886px 100%;
        background-position: -964px center;
        min-height: unset;

        > .container {

            > .title {

                > h2 {
                    font-size: 24px;
                    line-height: 36px;
                }

                > .subtitle {
                    font-size: 14px;
                    line-height: 21px;
                }
            }

            > .blocks {
                margin-top: 30px;
                display: flex;

                > .column {
                    row-gap: 10px;
                    width: 100%;
                }
            }
        }
    }
}
</style>
