<template>
    <div class="bottom-society-block">
        <div class="container">
            <h3 class="title">
                {{ $i18n.get(`Be passionate`) }}
            </h3>

            <div class="description" v-html="$i18n.get(`If our approach and what we do are close to you, then you are already in the ranks of TRUE people. No matter if you are a simple observer or an active figure. All you need is to choose a convenient way to join the project and become a part of it.`)"></div>
            <media-buttons></media-buttons>
            <div class="z-bro-mirror"></div>

            <div class="bottom-title">
                {{ $i18n.get(`Discover your own way to flip the GameFi industry`) }}
            </div>
        </div>
    </div>
</template>

<script>
import MediaButtons from "./MediaButtons";

export default {
    name: 'bottom-society-block',
    components: {
        'media-buttons': MediaButtons
    }
}
</script>

<style lang="less">
.bottom-society-block {
    background-image: url(../images/bottom-society-block-bg.webp);
    background-size: 2560px 100%;
    background-position: center;
    color: var(--white);
    background-repeat: no-repeat;
    padding: 79px 15px 84px 15px;
    overflow: hidden;
    max-height: 440px;

    > .container {
        max-width: var(--main-width-full);
        margin: 0 auto;
        position: relative;

        > .z-bro-mirror {
            position: absolute;
            top: -40px;
            right: 5%;
            width: 280px;
            height: 400px;
            background-image: url(../images/z-bro-mirror.webp);
            background-size: 280px 400px;
        }

        > .title {
            font-weight: 700;
            font-size: 46px;
            line-height: 69px;
            margin-bottom: 8px;
        }

        > .description {
            z-index: 10;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            opacity: 0.8;
            max-width: 600px;
            position: relative;
            margin-bottom: 22px;
        }

        > .media-buttons {
            z-index: 1;
            position: relative;
            margin-bottom: 28px;
        }

        > .bottom-title {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            display: inline-block;
            background: linear-gradient(85.65deg, #FCB42F -24.95%, #CB34E3 146.19%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            position: relative;
        }
    }
}

@media (max-width: 1279px) {
    .bottom-society-block {
        position: relative;

        > .container {
            position: initial;

            > .z-bro-mirror {
                top: 40px;
                right: 5%;
            }
        }
    }
}

@media (max-width: 1023px) {
    .bottom-society-block {
        padding: 53px 15px 80px 15px;
        background-image: url(../images/bottom-society-block-bg.webp);
        background-position: center;
        //background-size: 2560px 340px;
        position: relative;

        > .container {
            position: initial;

            > .z-bro-mirror {
                top: 40px;
                right: 3%;
            }

            > .title {
                font-size: 36px;
                line-height: 54px;
            }

            > .description {
                max-width: 451px;
                margin-top: 10px;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
            }

            > .media-buttons {
                max-width: 409px;

                > button {
                    width: 32%;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .bottom-society-block {
        padding: 0;
        background-image: url(../images/bottom-society-block-bg.webp);
        background-position: top 0 right 37%;
        background-size: 1800px 310px;
        background-repeat: no-repeat;
        background-color: var(--dark-blue);
        max-height: unset;

        > .container {
            background: linear-gradient(180deg, #001A54 14.66%, #0D255D 100%), #001A54;
            padding: 19px 15px 36px 15px;
            height: 100%;

            > .z-bro-mirror {
                top: 66px;
                right: 10%;
                width: 118px;
                height: 200px;
                background-size: 118px 200px;
                background-image: url(../images/z-bro-mirror-small.webp);
            }

            > .title {
                font-weight: bold;
                font-size: 24px;
                line-height: 36px;
            }

            > .description {
                max-width: 50%;
                font-size: 12px;
                line-height: 20px;
                min-height: 200px;
            }

            > .media-buttons {
                max-width: 100%;
                margin-bottom: 14px;

                > button {
                    width: 49%;
                }
            }

            .bottom-title {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
</style>
